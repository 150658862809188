<!-- 
  Shamelessly stolen and adapted from
  https://github.com/vuetifyjs/vuetify/blob/ca2174cff0b460fb8228b403c0068f9f855015c4/packages/vuetify/src/components/VOtpInput/VOtpInput.tsx
 -->
<script setup lang="ts">
const props = defineProps<{
  length: number;
  disabled?: boolean;
  placeholder?: string;
  autofocus?: boolean;
  label?: string;
  modelValue: string;
  inputClass?: unknown;
}>();
const emit = defineEmits<{
  'update:modelValue': [otp: string];
  finish: [otp: string];
}>();

const attrs = useAttrs();
const fields = computed(() => Array.from({ length: props.length }).fill(0));
const inputRef = ref<HTMLInputElement[]>([]);
const content = ref<HTMLDivElement | null>(null);
const focusIndex = ref(-1);
const current = computed(() => inputRef.value[focusIndex.value]);

const otp = computed(() => props.modelValue.split(''));
const updateModelValue = (value: string[]) => emit('update:modelValue', value.join(''));

watch(
  otp,
  (val) => {
    if (val.length === props.length && focusIndex.value === props.length - 1) {
      emit('finish', val.join(''));
    }
  },
  { deep: true },
);

watch(focusIndex, (val) => {
  if (val < 0) return;

  void nextTick(() => {
    inputRef.value[val]?.select();
  });
});

onMounted(() => {
  if (props.autofocus) {
    inputRef.value[0]?.focus();
  }
});

const onInput = () => {
  // The maxlength attribute doesn't work for the number type input, so the text type is used.
  // The following logic simulates the behavior of a number input.
  if (current.value && /\D/.test(current.value.value)) {
    current.value.value = '';
    return;
  }

  const array = otp.value.slice();
  const value = current.value?.value ?? '';

  array[focusIndex.value] = value;

  let target: null | number | 'next' = null;

  if (focusIndex.value > otp.value.length) {
    target = otp.value.length + 1;
  } else if (focusIndex.value + 1 !== props.length) {
    target = 'next';
  }

  updateModelValue(array);

  if (target && content.value) {
    focusChild(content.value, target);
  }
};

const onKeydown = (e: KeyboardEvent) => {
  const array = otp.value.slice();
  const index = focusIndex.value;
  let target: 'next' | 'prev' | 'first' | 'last' | number | null = null;

  if (!['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete'].includes(e.key)) return;

  e.preventDefault();

  if (e.key === 'ArrowLeft') {
    target = 'prev';
  } else if (e.key === 'ArrowRight') {
    target = 'next';
  } else if (['Backspace', 'Delete'].includes(e.key)) {
    array[focusIndex.value] = '';

    updateModelValue(array);

    if (focusIndex.value > 0 && e.key === 'Backspace') {
      target = 'prev';
    } else {
      requestAnimationFrame(() => {
        inputRef.value[index]?.select();
      });
    }
  }

  requestAnimationFrame(() => {
    if (target !== null && content.value) {
      focusChild(content.value, target);
    }
  });
};

const onPaste = (index: number, e: ClipboardEvent) => {
  e.preventDefault();
  e.stopPropagation();

  updateModelValue((e.clipboardData?.getData('Text') ?? '').split(''));

  inputRef.value[index]?.blur();
};

const onFocus = (_: FocusEvent, index: number) => {
  focusIndex.value = index;
};

const onBlur = () => {
  focusIndex.value = -1;
};

const focusableChildren = (el: Element, filterByTabIndex = true) => {
  const targets = ['button', '[href]', 'input:not([type="hidden"])', 'select', 'textarea', '[tabindex]']
    .map((s) => `${s}${filterByTabIndex ? ':not([tabindex="-1"])' : ''}:not([disabled])`)
    .join(', ');
  return [...el.querySelectorAll(targets)] as HTMLElement[];
};

const getNextElement = (
  elements: HTMLElement[],
  location?: 'next' | 'prev',
  condition?: (el: HTMLElement) => boolean,
) => {
  let _el;
  let idx = elements.indexOf(document.activeElement as HTMLElement);
  const inc = location === 'next' ? 1 : -1;
  do {
    idx += inc;
    _el = elements[idx];
  } while ((!_el?.offsetParent || !(condition?.(_el) ?? true)) && idx < elements.length && idx >= 0);

  return _el;
};

const focusChild = (el: Element, location?: 'next' | 'prev' | 'first' | 'last' | number) => {
  const focusable = focusableChildren(el);

  if (!location) {
    if (el === document.activeElement || !el.contains(document.activeElement)) {
      focusable[0]?.focus();
    }
  } else if (location === 'first') {
    focusable[0]?.focus();
  } else if (location === 'last') {
    focusable.at(-1)?.focus();
  } else if (typeof location === 'number') {
    focusable[location]?.focus();
  } else {
    const _el = getNextElement(focusable, location);
    if (_el) _el.focus();
    else focusChild(el, location === 'next' ? 'first' : 'last');
  }
};
</script>

<template>
  <div ref="content" class="content">
    <InputText
      v-for="(_, i) in fields"
      :key="i"
      :ref="(val) => (inputRef[i] = (val as ComponentPublicInstance | null)?.$el as HTMLInputElement)"
      autocomplete="one-time-code"
      inputmode="numeric"
      type="text"
      class="digit"
      :class="inputClass"
      :aria-label="label ?? ''"
      :autofocus="i === 0 && (autofocus ?? false)"
      :disabled="disabled ?? false"
      :min="0"
      :maxlength="1"
      :placeholder="placeholder ?? ''"
      :value="otp[i] ?? ''"
      @input="onInput"
      @focus="onFocus($event, i)"
      @blur="onBlur"
      @keydown="onKeydown"
      @paste="onPaste(i, $event)"
    />
    <input class="v-otp-input-input" type="hidden" :value="otp.join('')" v-bind="attrs" />
  </div>
</template>

<!-- eslint-disable vue/enforce-style-attribute -->
<style scoped>
.content {
  display: flex;
}

.digit {
  flex-shrink: 1;

  min-width: 0;
  max-width: 46px;
  height: 54px;
  margin-left: 4px;
  padding: 8px;

  text-align: center;

  &:first-child {
    margin: 0;
  }
}
</style>
